<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-form ref="form" :model="form" label-width="90px">
					<el-row :gutter="10">
						<el-col :span="6" :xs="24">
							<el-form-item label="日期"><el-date-picker v-model="form.valueData" type="date" value-format="YYYY-MM-DD" placeholder="请选择日期" /></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="userId"><el-input v-model="form.userId" clearable placeholder="请输入userId"></el-input></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="uuid"><el-input v-model="form.uuid" clearable placeholder="请输入uuid"></el-input></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24" class="el"><el-button type="primary" @click="jouShare">搜索</el-button></el-col>
					</el-row>
				</el-form>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" style="width: 100%" border stripe>
					<el-table-column prop="userId" label="userId"></el-table-column>
					<el-table-column prop="uuid" label="uuid"></el-table-column>
					<el-table-column prop="pid" label="上游代码位"></el-table-column>
					<el-table-column prop="ecpm" label="ECPM"></el-table-column>
					<el-table-column prop="num" label="数量"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
					<el-table-column label="操作" align="center" width="150">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="distributionClk(scope.row)">代码位分布</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
			</el-card>
			<el-dialog v-model="dialogdistribution" title="代码位分布" width="80%" :fullscreen="fullscreenshow">
				<el-table v-loading="serloadings" :data="tableDatas" :header-cell-style="{ background: '#eef1f6' }" style="width: 100%" border stripe>
					<el-table-column prop="userId" label="userId"></el-table-column>
					<el-table-column prop="uuid" label="uuid"></el-table-column>
					<el-table-column prop="pid" label="上游代码位"></el-table-column>
					<el-table-column prop="ecpm" label="ECPM"></el-table-column>
					<el-table-column prop="num" label="数量"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPages"
						v-model:page-size="pageSizes"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totalss"
						@size-change="handleSizeChanges"
						@current-change="handleCurrentChanges"
					/>
				</div>
				<template #footer>
					<span class="dialog-footer">
						<el-button type="primary" @click="dialogdistribution = false">确定</el-button>
						<el-button @click="dialogdistribution = false">取消</el-button>
					</span>
				</template>
			</el-dialog>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'videoDistribution',
	data() {
		return {
			fullscreenshow: false,
			dialogdistribution: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,

			serloadings: false,
			tableDatas: [],
			currentPages: 1,
			pageSizes: 10,
			totalss: null,
			pid: '',
			productId: '',
			form: {
				uuid: '',
				userId: '',
				valueData: ''
			}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
		// 时间初始化
		const start = new Date();
		start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
		this.form.valueData = this.$moment(start).format('YYYY-MM-DD');

		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取播完激励视频列表
		this.rewardPlayedRecordC();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取播完激励视频列表
				this.rewardPlayedRecordC();
			}
		}
	},
	methods: {
		// 获取播完激励视频列表
		rewardPlayedRecordC() {
			this.serloading = true;
			this.$HTTP.post(
				'rewardPlayedRecord/querySum',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					userId: this.form.userId,
					uuid: this.form.uuid,
					startDay: this.form.valueData,
					endDay: this.form.valueData
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;
			// 获取播完激励视频列表
			this.rewardPlayedRecordC();
		},
		// 弹框tab
		distributionClk(row) {
			this.pid = row.pid;
			this.dialogdistribution = true;
			// 获取弹框列表
			this.distributioPid();
		},
		// 获取弹框列表
		distributioPid() {
			this.serloadings = true;
			this.$HTTP.post(
				'rewardPlayedRecord/querySum',
				{
					productId: this.productId,
					pageNumber: this.currentPages,
					pageSize: this.pageSizes,
					startDay: this.form.valueData,
					endDay: this.form.valueData,
					pid: this.pid
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableDatas = response.data.data.records;
							this.totalss = response.data.data.total;
							this.serloadings = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取播完激励视频列表
			this.rewardPlayedRecordC();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取播完激励视频列表
			this.rewardPlayedRecordC();
		},
		// 弹框分页
		handleSizeChanges(val) {
			this.currentPages = 1;
			this.pageSizes = val;
			// 获取弹框列表
			this.distributioPid();
		},
		handleCurrentChanges(val) {
			this.currentPages = val;
			// 获取弹框列表
			this.distributioPid();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
